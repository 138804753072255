
import * as Sentry from '@sentry/vue'
import { Component } from 'vue-property-decorator'
import NotificationCard from '@/components/notification_card.vue'
import Notification from '@/interfaces/notification'
import SavageAimMixin from '@/mixins/savage_aim_mixin'

@Component({
  components: {
    NotificationCard,
  },
})
export default class NotificationView extends SavageAimMixin {
  loading = true

  notifications: Notification[] = []

  created(): void {
    this.load()
    document.title = 'Notifications - Savage Aim'
  }

  async load(): Promise<void> {
    // Load the team data from the API
    try {
      const response = await fetch('/backend/api/notifications/')
      if (response.ok) {
        // Parse the JSON into a team and save it
        this.notifications = (await response.json()) as Notification[]
        this.loading = false
      }
      else {
        super.handleError(response.status)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching Notifications.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  async markAllAsRead(): Promise<void> {
    try {
      const response = await fetch('/backend/api/notifications/', {
        method: 'POST',
        headers: {
          'X-CSRFToken': this.$cookies.get('csrftoken'),
        },
      })

      if (response.ok) {
        this.$store.dispatch('fetchNotifications')
        this.load()
      }
      else {
        super.handleError(response.status)
        this.$notify({ text: `Unexpected HTTP response ${response.status} received when marking notifications as read.`, type: 'is-danger' })
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when marking notifications as read.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }
}
